import Vue from "vue";
import PromptData from "@/components/PromptData.vue";
import i18n from "@/i18n";

export type DialogFieldData = Record<string, string>;

export type DialogFieldConfig = {
  label: string;
  message?: string;
  type: string;
};

type DialogConfig = {
  title: string;
  message?: string;
  confirmText: string;
  warningOnStatusCode?: number;
  successMessage: string;
  onConfirm: (
    data: DialogFieldData,
    extra_data?: Record<string, string>
  ) => void;
  fields: Record<string, DialogFieldConfig>;
  extraData?: Record<string, string>;
};

export function promptData(propsData: DialogConfig): void {
  const PromptDataComponent = Vue.extend(PromptData);
  new PromptDataComponent({
    i18n,
    el: document.createElement("div"),
    propsData
  });
}
