import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'main',
    component: Home
  },
  {
    path: "/confirm/attendance/:uid/:token/:type/:obj",
    name: "confirm-attendance",
    component: Home,
  },
  {
    path: "/confirm/updates/:uid/:token/:type/:obj",
    name: "confirm-updates",
    component: Home,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  Vue.nextTick(() => {
    document.title = "Meeting Democracy Veranstaltungen"
  })
})

export default router
