import Cookies from "js-cookie";
import {DialogFieldData} from "@/components";

type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH";

export class APIError extends Error {
  constructor(public readonly statusCode: number, message: string, public readonly errors: unknown) {
    super(message);
  }
}

async function request(
  method: HTTPMethod,
  endpoint: string,
  successStatus: number,
  data: any,
  authToken?: string,
  filter?: string,
) {
  const init = {
    headers: new Headers(),
    method: method,
    body: JSON.stringify(data),
  };
  const csrfToken = Cookies.get("csrftoken");
  if (csrfToken != undefined) {
    init.headers.set("X-CSRFToken", csrfToken);
  }
  if (authToken != undefined) {
    init.headers.set("Authorization", `Token ${authToken}`);
  }
  init.headers.set("Accept", "application/json");
  init.headers.set("Content-Type", "application/json");
  const response = await fetch(`/api/${endpoint}${filter ? "?" + filter : ""}`, init);
  if (response.status !== 204) {
    if (response.status === successStatus) {
      return await response.json();
    } else {
      throw new APIError(response.status, response.statusText, await response.json());
    }
  }
}

export class Event {
  constructor(public pk: number, public url: string, public title: string, public date_time: Date, public description: string, public image_url: string) {
  }

  static async getAll(): Promise<Event[]> {
    const today = new Date()
    const event_data: any[] = await request("GET", "events", 200, undefined, undefined, `min_date_time=${today.toISOString().substring(0, 10)}`)
    return event_data.map(
      item => {
        return new Event(item.pk, item.url, item.title, new Date(item.date_time), item.description, item.image_3by2)
      })
  }
}

export async function attendEvent(data: DialogFieldData, extraData?: Record<string, string>): Promise<void> {
  if (extraData) {
    await request("POST", "attendances", 201, {event: extraData.event, ...data})
  }
}

export async function wantUpdates(data: DialogFieldData): Promise<void> {
  await request("POST", "users", 201, {wants_updates: true, ...data})
}

export async function sendEmail(data: DialogFieldData): Promise<void> {
  await request("POST", "emails", 201, data)
}

export async function confirmAttendance(uid: string, token: string, rtid: string, rid: string): Promise<void> {
  await request("POST", "confirm/attendance", 204, {uid, token, rtid, rid});
}

export async function confirmUpdates(uid: string, token: string, rtid: string, rid: string): Promise<void> {
  await request("POST", "confirm/updates", 204, {uid, token, rtid, rid});
}
