


























import { attendEvent, Event } from "@/api";
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NotifyMixin } from "@/mixins";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/de";
import { promptData } from "@/components/index";

dayjs.extend(localizedFormat);
dayjs.locale("de");

@Component
export default class EventPreview extends mixins(NotifyMixin) {
  @Prop() private event!: Event;

  get formattedDateTime(): string {
    return dayjs(this.event.date_time).format("LLLL");
  }

  promptAttendEvent(): void {
    promptData({
      title: this.$t("event_preview.attend.title").toString(),
      confirmText: this.$t("event_preview.attend.save").toString(),
      warningOnStatusCode: 400,
      successMessage: this.$t(
        "event_preview.attend.success_message"
      ).toString(),
      onConfirm: attendEvent,
      fields: {
        attendee_last_name: {
          label: this.$t("event_preview.attend.name").toString(),
          type: "text"
        },
        attendee_first_name: {
          label: this.$t("event_preview.attend.first_name").toString(),
          type: "text"
        },
        attendee_email: {
          label: this.$t("event_preview.attend.email").toString(),
          type: "email"
        }
      },
      extraData: {
        event: this.event.url
      }
    });
  }
}
