import Component from "vue-class-component";
import Vue from "vue";

@Component
export class NotifyMixin extends Vue {
  public showError(): void {
    this.showMessage("Es ist leider ein Fehler aufgetreten.", "is-danger")
  }

  showSuccess(message: string): void {
    this.showMessage(message, "is-success")
  }

  showWarning(message: string): void {
    this.showMessage(message, "is-warning")
  }

  private showMessage(message: string, type: "is-success" | "is-danger" | "is-warning"): void {
    this.$buefy.toast.open({message, type, duration: 10000});
  }
}
