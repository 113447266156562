



































import { mixins } from "vue-class-component";
import { NotifyMixin } from "@/mixins";
import { Component, Prop } from "vue-property-decorator";
import { DialogFieldConfig, DialogFieldData } from "@/components/index";

@Component
export default class PromptData extends mixins(NotifyMixin) {
  @Prop() public title!: string;
  @Prop() public message?: string;
  @Prop() public confirmText!: string;
  @Prop() public successMessage!: string;
  @Prop() public fields!: Record<string, DialogFieldConfig>;
  @Prop() public onConfirm!: (
    data: DialogFieldData,
    extraData?: Record<string, string>
  ) => void;
  @Prop() public warningOnStatusCode?: number;
  @Prop() public extraData?: Record<string, string>;

  public isActive = false;
  public data: DialogFieldData = {};

  public created(): void {
    for (const field in this.fields) {
      this.data[field] = "";
    }
  }

  public beforeMount(): void {
    if (typeof window !== "undefined") {
      this.$nextTick(() => {
        const container = document.body;
        container.appendChild(this.$el);
      });
    }
  }

  public mounted(): void {
    this.isActive = true;
  }

  async confirm(): Promise<void> {
    this.isActive = false;
    try {
      await this.onConfirm(this.data, this.extraData);
      this.showSuccess(this.successMessage);
    } catch (e) {
      if (
        this.warningOnStatusCode &&
        e.statusCode === this.warningOnStatusCode
      ) {
        this.showWarning(e.errors.non_field_errors[0]);
      }
      this.showError();
    }
  }

  public get cancelText(): string {
    return this.$t("prompt_data.cancel").toString();
  }
}
