









































import { Component, Vue } from "vue-property-decorator";
import i18n from "@/i18n";

@Component
export default class App extends Vue {
  public async created(): Promise<void> {
    const res = await fetch("/settings.json");
    const settings = await res.json();
    i18n.locale = settings.lang;
  }
}
