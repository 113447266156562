import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    event_preview: {
      attend: {
        email: "Email address",
        first_name: "First name",
        link: "Take part in this event",
        name: "Name",
        save: "Save",
        success_message:
          "An email for confirmation of your email address was sent to you.",
        title: "Take part"
      }
    },
    home: {
      confirmation_success_message: "Your request was successfully confirmed.",
      propose_date: {
        confirm_text: "Propose",
        email: "Email address",
        link: "Propose another date",
        message:
          "Propose weekdays and times which suit better for your participation. We will try to optimize our offerings.",
        proposals: "Date proposals",
        proposals_hint: "Please enter weekdays with time periods.",
        success_message: "Thank you for your date proposals.",
        title: "Propose date"
      },
      stay_informed: {
        email: "Email address",
        first_name: "First name",
        link: "Stay informed about new events",
        name: "Name",
        save: "Save",
        success_message:
          "An email for confirmation of your email address was sent to you.",
        title: "Stay informed"
      },
      title: "Events"
    },
    navbar: {
      to_main_page: "to main page"
    },
    prompt_data: {
      cancel: "Cancel"
    }
  },
  de: {
    event_preview: {
      attend: {
        email: "E-Mail-Adresse",
        first_name: "Vorname",
        link: "An dieser Veranstaltung teilnehmen",
        name: "Name",
        save: "Speichern",
        success_message:
          "Ihnen wurde eine E-Mail zur Bestätigung Ihrer E-Mail-Adresse gesendet.",
        title: "Teilnehmen"
      }
    },
    home: {
      confirmation_success_message: "Ihre Anfrage wurde erfolgreich bestätigt.",
      propose_date: {
        confirm_text: "Vorschlagen",
        email: "E-Mail-Adresse",
        link: "Neuen Termin vorschlagen",
        message:
          "Hier können Sie Wochentage und Zeiten vorschlagen, die für Sie besser für eine Teilnahme passen würden. Dann können wir unser Angebot optimieren.",
        proposals: "Terminvorschläge",
        proposals_hint: "Bitte möglichst Wochentage mit Zeiträumen angeben.",
        success_message: "Vielen Dank für Ihre Terminvorschläge.",
        title: "Termin vorschlagen"
      },
      stay_informed: {
        email: "E-Mail-Adresse",
        first_name: "Vorname",
        link: "Über neue Termine informiert werden",
        name: "Name",
        save: "Speichern",
        success_message:
          "Ihnen wurde eine E-Mail zur Bestätigung Ihrer E-Mail-Adresse gesendet.",
        title: "Informiert bleiben"
      },
      title: "Veranstaltungen"
    },
    navbar: {
      to_main_page: "zur Hauptseite"
    },
    prompt_data: {
      cancel: "Abbrechen"
    }
  }
};

const i18n = new VueI18n({ locale: "en", messages });
export default i18n;
