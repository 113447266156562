import Vue from "vue";
import Buefy from "buefy";
import App from "./App.vue";
import i18n from "@/i18n";
import router from "./router";

import "./buefy.scss";
import "./main.scss";

Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");
