


























































import { Component } from "vue-property-decorator";
import {
  confirmAttendance,
  confirmUpdates,
  Event,
  sendEmail,
  wantUpdates
} from "@/api";
import EventPreview from "@/components/EventPreview.vue";
import { mixins } from "vue-class-component";
import { NotifyMixin } from "@/mixins";
import { promptData } from "@/components";

@Component({
  components: { EventPreview }
})
export default class Home extends mixins(NotifyMixin) {
  public events: Array<Event> = [];

  public async created(): Promise<void> {
    this.events = await Event.getAll();
    await this.checkConfirmation();
  }

  public promptUpdates(): void {
    promptData({
      title: this.$t("home.stay_informed.title").toString(),
      confirmText: this.$t("home.stay_informed.save").toString(),
      successMessage: this.$t("home.stay_informed.success_message").toString(),
      onConfirm: wantUpdates,
      fields: {
        last_name: {
          label: this.$t("home.stay_informed.name").toString(),
          type: "text"
        },
        first_name: {
          label: this.$t("home.stay_informed.first_name").toString(),
          type: "text"
        },
        email: {
          label: this.$t("home.stay_informed.email").toString(),
          type: "email"
        }
      }
    });
  }

  public promptProposals(): void {
    promptData({
      title: this.$t("home.propose_date.title").toString(),
      message: this.$t("home.propose_date.message").toString(),
      confirmText: this.$t("home.propose_date.confirm_text").toString(),
      successMessage: this.$t("home.propose_date.success_message").toString(),
      onConfirm: sendEmail,
      fields: {
        author_email: {
          label: this.$t("home.propose_date.email").toString(),
          type: "email"
        },
        message: {
          label: this.$t("home.propose_date.proposals").toString(),
          message: this.$t("home.propose_date.proposals_hint").toString(),
          type: "textarea"
        }
      }
    });
  }

  private async checkConfirmation() {
    if (
      this.$route.name === "confirm-attendance" ||
      this.$route.name === "confirm-updates"
    ) {
      try {
        if (this.$route.name === "confirm-attendance") {
          await confirmAttendance(
            this.$route.params.uid,
            this.$route.params.token,
            this.$route.params.type,
            this.$route.params.obj
          );
        } else {
          await confirmUpdates(
            this.$route.params.uid,
            this.$route.params.token,
            this.$route.params.type,
            this.$route.params.obj
          );
        }
        await this.$router.push({ name: "main" });
        this.showSuccess(
          this.$t("home.confirmation_success_message").toString()
        );
      } catch {
        this.showError();
      }
    }
  }
}
